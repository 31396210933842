import React, { useRef } from "react";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image"; 
import "./SelectUser.scss";
import { AsyncTypeahead } from "react-bootstrap-typeahead";
import { PersonFill } from "react-bootstrap-icons";
import { useFormContext } from "../hooks";
import { useTranslation } from "react-i18next";

const SelectUser = () => {
  const {
    isSearching,
    users,
    selectedUser,
    handleDeselectUser,
    handleSelectUser,
    handleSearch,
  } = useFormContext();

  const { t } = useTranslation();

  let selectUserRef = useRef();

  const handleOnFocus = () => {
    handleDeselectUser(selectUserRef);
  };

  return (
    <Form.Group>
      <AsyncTypeahead
        ref={selectUserRef}
        isLoading={isSearching}
        id="SelectUser"
        minLength={2}
        labelKey="name"
        onFocus={handleOnFocus}
        onChange={handleSelectUser}
        onSearch={handleSearch}
        options={users}
        defaultSelected={selectedUser && [selectedUser]}
        placeholder={t("ENROLL_PT_WHO_IS_YOUR_PROVIDER_PLACEHOLDER")}
        promptText={t("ENROLL_PT_WHO_IS_YOUR_PROVIDER_PROMPT")}
        searchText={t("ENROLL_PT_WHO_IS_YOUR_PROVIDER_SEARCHING")}
        renderMenuItemChildren={(option) => {
          return (
            <div className="user">
              <div className="user--profile-photo">
                {option.photoUrl ? (
                  <Image
                    className="user--profile-photo--image"
                    src={option.photoUrl}
                    alt={option.name}
                  />
                ) : (
                  <PersonFill color={`#CCCCCC`} size={45} />
                )}
              </div>
              <div className="user--details">
                <div className="user--name">{option.name}</div>
                <div className="user--meta">
                  {!!option.location && (
                    <span className="user--location">
                      {`${option.location.city}, ${option.location.region} - `}
                    </span>
                  )}
                  <span className="user--specialty">{option.specialty}</span>
                </div>
              </div>
            </div>
          );
        }}
      />
    </Form.Group>
  );
};

export default SelectUser;
