import React, {
  createContext,
  useCallback,
  useEffect,
  useState,
} from "react";
import { Trans } from "react-i18next";
import { VIDSCRIP_JOURNEYS_URL } from "../constants";
import { useQueryParams } from "../hooks/useQueryParams";
import {
  getUserRecord,
  searchUsers,
} from "../lib/VidscripApi";

export const FormContext = createContext({});

export const FormContextProvider = ({ children }) => {
  const queryUser = useQueryParams().get("user");

  const [isLoading, setIsLoading] = useState(false);
  const [isSearching, setIsSearching] = useState(false);
  const [alerts, setAlerts] = useState([]);
  const [users, setUsers] = useState();
  const [selectedUser, setSelectedUser] = useState();
  const [showUser, setShowUser] = useState(
    !(Number(useQueryParams().get("hideUser")) === 1)
  );

  const handleDeselectUser = useCallback(
    (selectUserRef) => {
      if (selectedUser) {
        setSelectedUser(undefined);
        selectUserRef.current.clear();
        selectUserRef.current.focus();
      }
    },
    [selectedUser]
  );

  const handleSelectUser = useCallback(
    ([user]) => {
      if (!isSearching && user !== selectedUser) {
        setSelectedUser(user);
        window.open(`${VIDSCRIP_JOURNEYS_URL}/u/${user.username}?referrer=${window.location.href}&referrerType=URL`, '_blank').focus();
      }
    },
    [isSearching, selectedUser]
  );

  const handleSearch = async (query) => {
    try {
      setAlerts([]);
      setIsSearching(true);
      const results = await searchUsers(query);
      setUsers(results.sort((a, b) => a.name.localeCompare(b.name)));
      setIsSearching(false);
    } catch (err) {
      console.error(`[Vidscrip]`, "Error", err);
      setIsSearching(false);
      setAlerts([
        {
          status: "danger",
          message: (
            <Trans i18nKey={"ENROLL_PT_WHO_IS_YOUR_PROVIDER_ERROR_MESSAGE"}>
              Hm. It appears we're having trouble connecting to Vidscrip. Please
              try again later. For immediate support contact;
              <a href="mailto:help@vidscrip.com">help@vidscrip.com</a>
            </Trans>
          ),
        },
      ]);
    }
  };

  useEffect(() => {
    if (!isLoading && queryUser && !selectedUser) {
      const getUserFromQuery = async () => {
        setIsLoading(true);
        const userRecord = await getUserRecord(queryUser);
        setUsers([userRecord]);
        handleSelectUser([userRecord]);
        setIsLoading(false);
      };
      !isLoading && getUserFromQuery();
    }
  }, [queryUser, selectedUser, isLoading, handleSelectUser]);

  return (
    <FormContext.Provider
      value={{
        alerts,
        handleDeselectUser,
        handleSearch,
        handleSelectUser,
        isLoading,
        isSearching,
        selectedUser,
        setSelectedUser,
        setShowUser,
        setAlerts,
        showUser,
        users,
      }}
    >
      {children}
    </FormContext.Provider>
  );
};
