import React from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "../hooks";
import Alerts from "./Alerts";
import SelectUser from "./SelectUser";

const EnrollmentForm = () => {
  const {
    isLoading,
    showUser,
  } = useFormContext();

  const { t } = useTranslation();

  if (isLoading)
    return (
      <div className="mt-3 mb-3">
        {t("ENROLL_PT_WHO_IS_YOUR_PROVIDER_LOADING")}
      </div>
    );

  return (
    <div
    className="mt-1 mb-1">
      <Alerts />

      {showUser && <SelectUser />}
      </div>
  );
};

export default EnrollmentForm;
