import { env } from "../lib/env";

export const VIDSCRIP_CONNECT_API = env.REACT_APP_VIDSCRIP_CONNECT_API;
export const VIDSCRIP_CONNECT_BASE_URL = env.REACT_APP_CONNECT_BASE_URL
export const VIDSCRIP_CONNECT_API_KEY = env.REACT_APP_VIDSCRIP_CONNECT_API_KEY;
export const API_URL = env.REACT_APP_VIDSCRIP_API_URL;
export const API_ANONYMOUS_TOKEN = env.REACT_APP_VIDSCRIP_API_TOKEN;
export const API_KEY =
  env.REACT_APP_AIRTABLE_VIDSCRIP_SMS_API_KEY ||
  env.AIRTABLE_VIDSCRIP_SMS_API_KEY;
export const BASE_ID =
  env.REACT_APP_AIRTABLE_VIDSCRIP_SMS_BASE_ID ||
  env.AIRTABLE_VIDSCRIP_SMS_BASE_ID;
export const VIDSCRIP_JOURNEYS_URL = env.REACT_APP_VIDSCRIP_JOURNEYS_URL;
