import React from "react";
import EnrollmentForm from "./components/EnrollmentForm";
import Container from "react-bootstrap/Container";
import "./App.scss";
import { FormContextProvider } from "./contexts/FormContext";
import { LanguageContext } from "./contexts/LanguageContext";

function App() {
  return (
    <Container className="EnrollmentFormApp" fluid>
      <FormContextProvider>
        <LanguageContext>
          <EnrollmentForm />
        </LanguageContext>
      </FormContextProvider>
    </Container>
  );
}

export default App;
