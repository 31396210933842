import React, { useEffect } from "react";
import { useQueryParams } from "../hooks/useQueryParams";
import { useTranslation } from "react-i18next";

export const LanguageContext = ({ children }) => {
  const queryParams = useQueryParams();
  const language = queryParams.get("language");
  const { i18n } = useTranslation();

  useEffect(() => {
    if (language) {
      i18n.changeLanguage(language);
    }
  }, [language, i18n]);

  return <>{children}</>;
};
