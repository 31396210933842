import React from "react";
import { Alert } from "react-bootstrap";
import { useFormContext } from "../hooks";

const Alerts = () => {
  const { alerts } = useFormContext();

  return (
    <div id="alerts">
      {alerts.map(
        (a, index) =>
          a && (
            <Alert key={`alert-${index}`} variant={a.status}>
              {a.message || a.error}
            </Alert>
          )
      )}
    </div>
  );
};

export default Alerts;
